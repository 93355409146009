import React from 'react'
import { Link, graphql } from 'gatsby';
import styled from 'styled-components'
import Img from "gatsby-image"
import Text, { Section } from '../components/Theme'
// import { system, Grid } from 'styled-system'
//import { HelmetDatoCms } from 'gatsby-source-datocms'
//import Img from 'gatsby-image'
import Layout from "../components/layout"
import Header from "../components/Header";
import Footer from '../components/footer';

const PressRow = styled(Section)`
  border-top: 1px solid rgba(0,0,0,.1);
  
  padding: 0;
  align-items: start;
  padding: 80px 0;
  h3 {
      padding-top: 10px;
  }
  img {
      align-self: center;
      max-width: 130px;
      max-height:150px;
      
  }
`;
const FeatureRow = styled(Section)`
    grid-column: 2/12;
    margin-top: -120px;
    padding: 0;
`;
const PressLink = styled.a`
  display: block;
  grid-column: 2/12;
`;
const Date = styled(Text)`
  text-align: right;
`;
const Press = ({ data}) => (
    <Layout>
        
            <Header content_color="black" />
            <Section large>
                <Text ultra col="2/12">Press</Text>
                <Text body col="2/12">Explore select stories and exhibitions<br />where Overview has been featured:<br /><br /></Text>
                <Text caption col="2/12"><Link to="contact">Contact for press inquiries &rarr;</Link></Text>
                
                
            </Section>

            <Section large>
                <FeatureRow>
                {data.logos.edges.map(({ node: article }) => (   
                    
                        <Text col="span 3">
                            <a href={article.url} target="_blank"><Img fixed={article.logo.fixed} /></a>
                        </Text>
                    
                    ))}
                </FeatureRow>
            </Section>
            <Section large stacked>

            {data.articles.edges.map(({ node: article }) => (
                <PressLink href={article.url}>
                    <PressRow>
                        <Text caption col="span 3"><Img fixed={article.logo.fixed} /></Text>
                        <Text sub col="span 7">{article.title}</Text>
                        <Date caption col="span 3">{article.date}</Date>
                    </PressRow>
                </PressLink>
            ))}

            </Section>


        
        <Footer />
    </Layout>
)

export default Press

export const query = graphql`
  query PressQuery {
    logos: allDatoCmsPress(filter: {featuredArticle: {eq: true}}) {
        edges {
            node {
                
                url
                featuredArticle
                
                logo {
                    fixed(width: 160, height: 160) {
                        ...GatsbyDatoCmsFixed
                    }
                }
            }
        }
        
    }
    articles: allDatoCmsPress(sort: {fields: date, order: DESC}) {
        edges {
            node {
                title
                url
                featuredArticle
                date
                logo {
                    fixed(width: 70, height: 70) {
                        ...GatsbyDatoCmsFixed
                    }
                }
            }
        }
        
    }
  }
`
